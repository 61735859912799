import { refreshReq } from "./keycloak/user/raw-requests";

import { getTokenPayload } from "@/lib/common/jwt";
import { tokenStorage } from "@/lib/storage/token";


const authInterceptor = async () => {

	if (process.env.STORYBOOK) {
		return "mocked-token";
	};

	const { getTokens, setTokens } = tokenStorage();
	const { accessToken, refreshToken } = getTokens();

	if (getTokenPayload(accessToken)) return accessToken;
	if (!getTokenPayload(refreshToken)) {
		return null;
	}
	try {
		const { access_token, refresh_token } = await refreshReq({ refreshToken: refreshToken! });

		setTokens({ accessToken: access_token, refreshToken: refresh_token });
		return access_token;
	} catch {
		return null;
	}
};

export {
	authInterceptor,
};

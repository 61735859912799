import jwtDecode from 'jwt-decode';


const getTokenPayload = (jwtToken: string | null) => {
  if (!jwtToken) return null;
  const decoded = jwtDecode<{
    exp: number;
    resource_access?: {
      mes?: { roles?: string[] };
      'realm-management'?: { roles?: string[] };
    };
    realm_access?: { roles?: string[] };
    groups?: string[];
    preferred_username?: string;
    given_name?: string;
    family_name?: string;
    name?: string;
    email?: string;
    default_tool?: string;
  }>(jwtToken);
  if (Date.now() >= decoded.exp * 1000) {
    return null;
  }
  return decoded;
};

export {
  getTokenPayload,
};

import { type Credentials } from "./types";

import { getRestInstance } from "@/api/index.rest";
import { getEnvVars } from "@/lib/common/get-env-vars";

const { env } = getEnvVars();

const refreshReq = async (payload: { refreshToken: string }): Promise<Credentials> => {
	const response = await getRestInstance("noauth")
		.post(
			`/${env.KEYCLOAK}/token/protocol/openid-connect/token`,
			new URLSearchParams({
				grant_type: "refresh_token",
				client_id: "mes",
				refresh_token: payload.refreshToken
			})
	);
	return response.data;
};

const logoutReq = async (payload: { refreshToken: string }): Promise<void> => {
	await getRestInstance("noauth")
		.post(
			`/${env.KEYCLOAK}/token/protocol/openid-connect/logout`,
			new URLSearchParams({
				client_id: "mes",
				refresh_token: payload.refreshToken
			})
	);
};

export {
	refreshReq, logoutReq
};

export default {
  'menu.auth-broker': 'SSO',
  'menu.welcome': 'Добро пожаловать',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Главная',
  'menu.main': '/',
  'menu.root': 'Админ',
  'menu.root.sub-page': 'Sub-Page',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register-result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Таблица',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account editor',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
  'menu.about': 'О программе',

  'menu.system': 'Системные настройки',
  'menu.system.grafana': 'Grafana',
  'menu.system.hasura': 'Hasura',
  'menu.system.auth-broker': 'Keycloak',
  'menu.system.database': 'База данных',
  'menu.system.report': 'JasperReport',
  'menu.system.document-management': 'NextCloud',
  'menu.system.mailing': 'Интеграция в MS',
  'menu.system.logger': 'Graylog',

  'menu.admin': 'Административные настройки',
  'menu.admin.global': 'Глобальные',
  'menu.admin.menu': 'Меню',
  'menu.admin.tools': 'Инструменты',
  'menu.admin.auth': 'Авторизация',
  'menu.admin.notifications': 'Уведомления',
  'menu.admin.permissions': 'Разрешения',
  'menu.admin.users': 'Пользователи',

  'menu.settings': 'Einstellungen',
  'menu.settings.application': 'Anwendung',
  'menu.settings.tools': 'Werkzeuge',
  'menu.settings.navigation': 'Navigation',
  'menu.settings.users': 'Benutzer',
  'menu.settings.groups': 'Gruppen',
  'menu.settings.services': 'Dienstleistungen',
  'menu.settings.onboarding': 'Onboarding',

  'menu.tools': 'Инструменты',
  'menu.tools.grafana': 'Grafana',
  'menu.tool': 'Инструмент',
};

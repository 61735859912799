import fileManager from './component/fileManager';
import { loggerViewerLocaleRu } from '@/components/tools/logger-viewer/locales';
import logger from '@/locales/ru-RU/component/logger';
import { useLocale } from "@/hooks/useLocale";

export default {
  // Common
  'component.file-not-found': 'Datei nicht gefunden',
  'component.to-detach-file': 'Datei abhängen?',
  'component.to-attach': 'Anhängen',
  'component.rename': 'Umbenennen',
  'component.file-name': 'Dateiname',
  'component.size': 'Größe',
  'component.directories': 'Verzeichnisse',
  'component.path': 'Pfad',
  'component.enter-a-fractional-number': 'Geben Sie eine Dezimalzahl ein',
  'component.confirm-delete': 'Sind Sie sicher, dass Sie löschen möchten',
  'component.confirm-delete-with-filler': 'Sind Sie sicher, dass Sie {title} löschen möchten?',
  'component.to-block': 'Blockieren',
  'component.to-unblock': 'Freigeben ',
  'component.read-only': 'Только чтение',
  'component.are-you-sure-delete-this-panel': 'Вы уверены в удалении этой панели?',
  'component.autoheight': 'Автовысота',
  'component.click-action': 'Действие по клику',
  'component.enable': 'Доступно',
  'component.tagSelect.expand': 'Expand',
  'component.tagSelect.collapse': 'Collapse',
  'component.tagSelect.all': 'Alle',
  'component.logout': 'Abmelden',
  'component.save': 'Сохранить',
  'component.clear': 'Очистить',
  'component.test': 'Тест',
  'component.login': 'Логин',
  'component.username': 'Benutzername',
  'component.password': 'Passwort',
  'component.create': 'Erstellen',
  'component.sync': 'Синхронизация',
  'component.delete': 'Löschen',
  'component.move': 'Переместить',
  'component.format': 'Форматировать',
  'component.upload': 'Hochladen',
  'component.download': 'Herunterladen',
  'component.reset': 'Сброс',
  'component.internalUrl': 'Адрес (внутренний)',
  'component.externalUrl': 'Адрес (внешний)',
  'component.finish': 'Завершить',
  'component.enabled': 'Включена',
  'component.disabled': 'Выключена',
  'component.not-available': 'Извините, данная страница вам недоступна',
  'component.home': 'На Главную',
  'component.on': 'Вкл',
  'component.off': 'Выкл',
  'component.add': 'Hinzufügen',
  'component.edit': 'Bearbeiten',
  'component.addition': 'Hinzufügung',
  'component.updating': 'Обновление',
  'component.deleting': 'Löschen',
  'component.renaming': 'Umbenennen',
  'component.uploading': 'Hochladen',
  'component.downloading': 'Herunterladen',
  'component.confirm-delete-tool-title': 'Вы уверены, что хотите удалить?',
  'component.confirm-delete-menu-title': 'Вы уверены, что хотите удалить меню ',
  'component.confirm-delete-menu-group': 'Вы уверены, что хотите удалить группу ',
  'component.group-has-been-added': 'Эта группа уже добавлена!',
  'component.confirm-create-menu-title': 'Создать меню?',
  'component.yes': 'Ja',
  'component.no': 'Nein',
  'component.cancel': 'Abbrechen',
  'component.profile': 'Profil',
  'component.label.first-name': 'Имя',
  'component.label.last-name': 'Фамилия',
  'component.label.email': 'E-mail',
  'component.placeholder.first-name': 'Пример: Иван',
  'component.placeholder.last-name': 'Пример: Иванов',
  'component.placeholder.email': 'Пример: login@mail.ru',
  'component.name_filer': 'Фильтр по имени',
  'component.type_filer': 'Фильтр по типу',
  'component.all': 'Alle',
  'component.show_disabled': 'Отображать недоступные',
  'component.preview': 'Предпросмотр',
  'component.name': 'Name',
  'component.description': 'Описание',
  'component.label': 'Ярлык',
  'component.type': 'Тип',
  'component.menu': 'Меню',
  'component.folder': 'Папка',
  'component.title.settings': 'Настройки',
  'component.title.add_tool': 'Добавить инструмент',
  'component.theme': 'Тема',
  'component.current': 'Текущая',
  'component.light': 'Светлая',
  'component.medium': 'Средняя',
  'component.dark': 'Тёмная',
  'component.full': 'Полный',
  'component.kiosk': 'Киоск',
  'component.kiosk_tv': 'Киоск ТВ',
  'component.view': 'Вид',
  'component.client': 'Клиент',
  'component.client_tooltip': 'Клиенские настройки',
  'component.server': 'Сервер',
  'component.server_tooltip': 'Серверные настройки',
  'component.loading': 'Загрузка...',
  'component.version': 'Версия',
  'component.default': 'По умолчанию',
  'component.title.confirm.share.enabled': 'Are you sure set property to enable?',
  'component.title.confirm.share.disabled': 'Are you sure set property to disable?',
  'component.error.tool-unsupported': 'Инструмент не поддерживается',
  'component.example': 'Пример',
  'component.error': 'Ошибка',
  'component.endpoint': 'Конечная точка',
  'component.report': 'Bericht',
  'component.panel': 'Panel',
  'component.dashboard': 'Dashboard',
  'component.panel_on_click': 'Панель onClick',
  'component.constructor': 'Конструктор',
  'component.variables': 'Переменные',
  'component.fill': 'Заполнить',
  'component.range': 'Диапазон',
  'component.select_range': 'Выбор диапазона',
  'component.range_left_border': 'диапазон.левая граница',
  'component.range_right_border': 'диапазон.правая граница',
  'component.visible': 'Видимый',
  'component.use_global_property': 'Использовать глобальное свойство',
  'component.default_value': 'Значение по умолчанию',
  'component.presets': 'Предустановки',
  'component.templates': 'Шаблоны',
  'component.functions': 'Функции',
  'component.Last_5_minutes': 'Последние 5 минут',
  'component.Last_15_minutes': 'Последние 15 минут',
  'component.Last_30_minutes': 'Последние 30 минут',
  'component.Last_1_hour': 'Последний час',
  'component.Last_3_hours': 'Последние 3 часа',
  'component.Last_6_hours': 'Последние 6 часов',
  'component.Last_12_hours': 'Последние 12 часов',
  'component.Last_24_hours': 'Последние 24 часа',
  'component.Last_2_days': 'Последние 2 дня',
  'component.Last_7_days': 'Последние 7 дней',
  'component.Last_30_days': 'Последние 30 дней',
  'component.Last_90_days': 'Последние 90 дней',
  'component.Last_6_months': 'Последние 6 месяцев',
  'component.Last_1_year': 'Последний год',
  'component.Last_2_years': 'Последние 2 года',
  'component.Last_5_years': 'Последние 5 лет',
  'component.Current_day': 'Текущий день',
  'component.Current_week': 'Текущая неделя',
  'component.Current_month': 'Текущий месяц',
  'component.Current_quarter': 'Текущий квартал',
  'component.Current_year': 'Текущий год',
  'component.Yesterday': 'Вчера',
  'component.Last_week': 'Прошлая неделя',
  'component.Last_month': 'Прошлый месяц',
  'component.Last_quarter': 'Прошлый квартал',
  'component.Last_year': 'Прошлый год',
  'component.event': 'Событие',
  'component.events': 'События',
  'component.onClick': 'Клик',
  'component.action': 'Действие',
  'component.sort': 'Сортировка',
  'component.service_unavailable': 'Сервис недоступен',
  'component.show': 'Zeigen',
  'component.hide': 'Скрыть',
  'component.attributes': 'Атрибуты',
  'component.actions': 'Aktionen',
  'component.state': 'Состояние',
  'component.multiple_values': 'Несколько значений',
  'component.include': 'Включить',
  'component.select_all': 'Выбрать все',
  'component.values_separated_by_comma': 'Значения разделенные запятой',
  'component.decorate': 'Оформление',
  'component.logo': 'Логотип',
  'component.russian': 'Russisch',
  'component.english': 'Englisch',
  'component.kazakh': 'Kasachisch',
  'component.deutsch': 'Deutsch',
  'component.style': 'Стиль',
  'component.navigation': 'Navigation',
  'component.top': 'Сверху',
  'component.left': 'Слева',
  'component.mix': 'Смешанная',
  'component.visibility': 'Видимость',
  'component.select-range': 'Выбор диапазона',
  'component.absolute': 'Абсолютное',
  'component.relative': 'Относительное',
  'component.token': 'Токен',
  'component.from': 'из',
  'component.change-value': 'Изменить значение',
  'component.applicationName': 'Имя приложения',
  'component.source': 'Источник',
  'component.values': 'Значения',
  'component.multi-values': 'Mehrere Werte',
  'component.include-select-all': 'Aktivieren [Alle]',
  'component.query': 'Запрос',
  'component.custom': 'Пользовательский',
  'component.value': 'Значение',
  'component.constant': 'Константа',
  'component.link': 'Связная',
  'component.type-value': 'Тип переменной',
  'component.input-variable-name': 'Задайте имя переменной!',
  'component.input-rule-name': 'Задайте имя правила!',
  'component.name-already-exists': 'Запись с таким именем уже существует!',
  'component.date-output-format': 'Формат вывода',
  'component.date-output-accuracy': 'Точность ввода',
  'component.second': 'секунда',
  'component.minute': 'минута',
  'component.hour': 'час',
  'component.day': 'день',
  'component.week': 'неделя',
  'component.month': 'месяц',
  'component.quarter': 'квартал',
  'component.year': 'год',
  'component.invalid-variable-value': 'Не верное значение переменной',
  'component.change': 'Изменить',
  'component.unpin': 'Abhängen',
  'component.removed': 'Удален',
  'component.not-removed': 'Не удален',
  'component.added': 'Добавлен',
  'component.not-added': 'Не добавлен',
  'component.changed': 'Geändert',
  'component.not-changed': 'Не изменен',
  'component.user-created': 'пользователь, который добавил',
  'component.user-updated': 'пользователь, который обновил',
  'component.date-updated': 'дата обновления',
  'component.date-created': 'дата добавления',
  'component.conditional-formatting': 'Условное форматирование',
  'component.rule': 'Правило',
  'component.cell': 'Ячейки',
  'component.please-select': 'Выберите из списка',
  'component.formatter': 'Форматер',
  'component.font-color': 'Цвет шрифта',
  'component.background-color': 'Цвет фона',
  'component.font': 'Шрифт',
  'component.font-size': 'Размер шрифта',
  'component.condition': 'Zustand',
  'component.localization.support': 'Поддержка локализации',
  'component.not-path-for-panel': 'Не указан путь для панели',
  'component.access': 'Доступ',
  'component.info': 'Инфо',
  'component.system-info': 'Системная информация',
  'component.width': 'Ширина',
  'component.empty': 'пустое',
  'component.file': 'Файл',
  'component.not-found': 'не найден',
  'component.clone': 'Клонировать',
  'component.row-number': 'Номер строки',
  'component.invalid-value': 'Введено неверное значение',

  // Grafana
  'component.grafana.internal': 'Адрес сервера(внутренний)',
  'component.grafana.external': 'Внешняя ссылка',
  'component.grafana.basic-api-uri': 'Basic api uri',
  'component.grafana.api-key': 'Api key',
  'component.footer.allRightReserved': 'Alle Rechte vorbehalten',
  'component.roles': 'Роли',
  'component.groups': 'Группы',
  'component.tools': 'Инструменты',
  'component.grafana.remove-panel': 'Удалить панель',
  'component.grafana.columns-count': 'Столбцы',
  'component.grafana.row-height': 'Высота строк',
  'component.grafana.user-transformation': 'Изменять панели во вьювере',
  'component.grafana.stretch-to-window-height': 'Растянуть на высоту окна',
  'component.grafana.edit-panel': 'Редактировать панель',
  'component.grafana.panel-group': 'Группа панелей',
  'component.grafana.use-board': 'Использовать доску',
  'component.grafana.system-info-error': 'Не удалось получить информацию по сервису',
  'component.grafana.add-panel': 'Добавить',

  // System db
  'component.system-db.hostname': 'хост',
  'component.system-db.port': 'порт',
  'component.system-db.name': 'имя базы данных',
  'component.system-db.username': 'имя пользователя',
  'component.system-db.driver': 'драйвер',
  'component.system-db.want-to-delete': 'Эта операция необратима. Вы уверены, что хотите удалить системную базу данных?',
  'component.system-db.want-to-format': 'Эта операция необратима. Вы уверены, что хотите форматировать системную базу данных?',

  // Sso
  'component.sso.realm': 'Область',
  'component.sso.clientId': 'ID клиента',
  'component.sso.port': 'Порт',
  'component.sso.secret': 'Секрет',
  'component.sso.system-info-error': 'Не удалось получить информацию по сервису',

  //Rs
  'component.rs.system-info-error': 'Не удалось получить информацию по сервису',

  // User-profile
  'component.profile.login-name': 'Имя входа',
  'component.profile.new-password': 'Новый пароль',
  'component.profile.new-password-repeat': 'Новый пароль [повтор]',
  'component.profile.change-password': 'Сменить пароль',

  // Hasura
  'component.hasura.service-address': 'Адрес сервиса',
  'component.hasura.server': 'Сервер',
  'component.hasura.server-settings': 'Настройки сервера',
  'component.hasura.select.label': 'Таблица',
  'component.hasura.select.placeholder': 'Выберите таблицу',
  'component.hasura.fields': 'Поля',
  'component.hasura.table.label': 'Ярлык',
  'component.hasura.table.type': 'Тип',
  'component.hasura.table.attributes': 'Атрибуты',
  'component.hasura.table.action': 'Действие',
  'component.hasura.field': 'Поле',
  'component.hasura.no-description': 'Нет описания',
  'component.hasura.fields-not-configured': 'Не настроены поля',
  'component.hasura.form-constructor': 'Конструктор формы ввода/вывода',
  'component.hasura.min-height': 'Мин. высота',
  'component.hasura.min-width': 'Мин. ширина',
  'component.hasura.max-height': 'Макс. высота',
  'component.hasura.max-width': 'Макс. ширина',
  'component.hasura.columns-quantity': 'Кол-во столбцов',
  'component.hasura.missing-primary-key': 'Отсутствует первичный ключ',
  'component.hasura.table-settings': 'Настройки таблицы',
  'component.hasura.decorate': 'Оформление',
  'component.hasura.fitAndFill': 'Строки на всю ширину таблицы',
  'component.hasura.rowHeight': 'Высота строки',
  'component.hasura.fix': 'Фиксатор',
  'component.hasura.rowsTop': 'Сверху',
  'component.hasura.colsLeft': 'Слева',
  'component.hasura.colsRight': 'Справа',
  'component.hasura.export': 'Экспорт',
  'component.hasura.import': 'Импорт',
  'component.hasura.output': 'Вывод',
  'component.hasura.relation': 'Отношение',
  'component.hasura.restrictions': 'Ограничения',
  'component.hasura.content-type': 'Тип контента',
  'component.hasura.no-value': 'Нет значения',
  'component.hasura.source': 'Источник',
  'component.hasura.matrix': 'Матрица',
  'component.hasura.axisY': 'Ось Y',
  'component.hasura.axisX': 'Ось X',
  'component.hasura.matrix-field': 'Поле матрицы',
  'component.hasura.nesting-level': 'Уровень вложенности',
  'component.hasura.pagination': 'Пагинация',
  'component.hasura.rows-per-page': 'строк/стр',
  'component.hasura.conditions': 'Условия',
  'component.hasura.sorting-type': 'Тип сортировки',
  'component.hasura.soft': 'Мягкое',
  'component.hasura.invalid-json-format': 'Невалидный json-формат',
  'component.hasura.invalid-xml-format': 'Невалидный xml-формат',
  'component.hasura.adding-group': 'Добавление группы',
  'component.hasura.changing-group': 'Изменение группы',
  'component.hasura.permissions': 'Права',

  // Global
  'component.global.name-restriction': 'Текст не более 50 символов',
  'component.global.desc-restriction': 'Текст не более 255 символов',
  'component.global.language-selection': 'Язык',
  'component.global.default-value': 'Standardwert',
  'component.global.languages': 'Список',

  // AuthTools
  'component.auth.users': 'Пользователи',
  'component.auth.disabled-users': 'Заблокированные пользователи ',
  'component.auth.groups': 'Группы',
  'component.auth.disabled-tools': 'Заблокированные инструменты',
  'component.auth.disabled': 'Недоступен',
  'component.auth.enabled': 'Доступен',
  'component.auth.inherited': 'Унаследовано',
  'component.auth.default': 'По умолчанию',
  'component.auth.name-filter': 'Фильтр по именам',
  'component.auth.tools-filter': 'Фильтр по инструментам',

  // Navigation
  'component.navigation.change-directory': 'Изменить директорию',
  'component.navigation.change-tool': 'Изменить инструмент',

  // Crud
  'component.function.pagination-top': 'Верх',
  'component.function.pagination-bottom': 'Низ',
  'component.ui.CrudFormConstructor.tooltipColumnsQuantity': 'Сначала освободите занятое элементами место, а потом уменьшайте значение',

  // report
  'component.report.path-not-set': 'Не задан путь для отчета!',
  'component.report.time-functions': 'Функции времени',

  // DataInput
  'component.data-input.choose': 'выбрать',
  'component.data-input.download': 'Herunterladen',
  'component.data-input.upload': 'загрузить',
  'component.data-input.new-file': 'Neue Datei',
  'component.data-input.date-of-download': 'дата загрузки',
  'component.data-input.name-of-uploaded-file': 'Имя загруженного файла',
  'component.data-input.no-file-selected': 'Не выбран файл',
  'component.data-input.delete-the-selected-file': 'Удалить выбранный файл?',
  'component.data-input.enter-file-name': 'Введите название файла',

  // DataInputs placeholders
  'component.data.input.int': 'Пример: 123',
  'component.data.input.bigint': 'Пример: 123',
  'component.data.input.string': 'Пример: Символы',
  'component.data.input.xml': 'Пример: Символы',
  'component.data.input.float8': 'Пример: 123.321',
  'component.data.input._float8': 'Пример: 123.321',
  'component.data.input._int4': 'Пример: 123',
  'component.data.input.timestamp': ' Wählen Sie Datum und Uhrzeit aus',
  'component.data.input.timestamptz': 'Выберите дату и время',
  'component.data.input.date': 'Wählen Sie das Datum aus',
  'component.data.input.bpchar': 'Geben Sie Text ein',
  'component.data.input.object': 'Wählen Sie aus der Liste',
  'component.data.input.time': 'Wählen Sie die Zeit aus',
  'component.data.input._varchar': 'Пример: абв',
  'component.data.input.uuid': 'Geben Sie die UUID ein',
  'component.data.input.json': 'Пример: { "fileName": "my file name", "fileSize": 1024 }',
  'component.data.input.jsonb': 'Пример: { "fileName": "my file name", "fileSize": 1024 }',
  'component.data.input.file-selection': 'Выбор файла',

  // ArrayEditor
  'component.array-editor.delete-the-selected-row': 'Sind Sie sicher, dass Sie diese Zeile löschen möchten?',

  // IOCoordinates
  'component.io-coordinates.map': 'Karte',

  // IOJsonbSys
  'component.io-jsonb-sys.history': 'История',
  'component.io-jsonb-sys.user': 'Пользователь',
  'component.io-jsonb-sys.timestamp': 'Метка времени',
  'component.io-jsonb-sys.adding': 'Добавление',
  'component.io-jsonb-sys.editing': 'Редактирование',
  'component.io-jsonb-sys.deleting': 'Удаление',

  // ActionButton
  'action.button-create': 'Hinzufügen',
  'action.button-save': 'Speichern',
  'action.button-cancel': 'Abbrechen',
  'action.button-delete': 'Löschen',
  'action.button-clone': 'Klonen',
  'action.button-import': 'Importieren',
  'action.button-export': 'Exportieren',
  'action.button-download': 'Herunterladen',
  'action.button-upload': 'Hochladen',
  'action.button-reset': 'Zurücksetzen',
  'action.button-play': 'Starten',
  'action.button-login': 'Anmelden',
  'action.button-fill': 'Ausfüllen',
  'action.button-update': 'Aktualisieren',
  'action.button-edit': 'Bearbeiten ',
  'action.button-remove': 'Weglegen',

  // FieldFilter
  'modal.field-filter-modal.filter': 'Фильтр',
  'component.field-filter.filter': 'Filter',
  'component.field-filter.type': 'Typ',
  'component.field-filter.off': 'Ausgeschaltet',
  'component.field-filter.eq': 'Gleich',
  'component.field-filter.neq': 'Nicht gleich',
  'component.field-filter.gt': 'Größer als',
  'component.field-filter.gte': 'Größer als oder gleich',
  'component.field-filter.in': 'In der Liste',
  'component.field-filter.nin': 'Nicht in der Liste',
  'component.field-filter.is_null': 'Ist null',
  'component.field-filter.lt': 'Kleiner als',
  'component.field-filter.lte': 'Kleiner als oder gleich',
  'component.field-filter.like': 'Ähnlich (inhaltlich, berücksichtigt Groß-/Kleinschreibung)',
  'component.field-filter.nlike': 'Nicht ähnlich (inhaltlich, berücksichtigt Groß-/Kleinschreibung)',
  'component.field-filter.ilike': 'Ähnlich (inhaltlich, nicht berücksichtigt Groß-/Kleinschreibung)',
  'component.field-filter.nilike': 'Nicht ähnlich (inhaltlich, nicht berücksichtigt Groß-/Kleinschreibung)',
  'component.field-filter.similar': 'Ähnlich (strukturell)',
  'component.field-filter.nsimilar': 'Nicht ähnlich (strukturell)',
  'component.field-filter.regex': 'Regulärer Ausdruck (berücksichtigt Groß-/Kleinschreibung)',
  'component.field-filter.nregex': 'Kein regulärer Ausdruck (berücksichtigt Groß-/Kleinschreibung)',
  'component.field-filter.iregex': 'Regulärer Ausdruck (nicht berücksichtigt Groß-/Kleinschreibung)',
  'component.field-filter.niregex': 'Kein regulärer Ausdruck (nicht berücksichtigt Groß-/Kleinschreibung)',
  'component.field-filter.on': 'Aktiviert',
  'component.field-filter.value': 'Wert',

  // editor/application
  'page.settings-application.general': 'Общие',
  'page.settings-application.text': 'Текст',
  'page.settings-application.logoMesone': 'Логотип MESone',
  'page.settings-application.footer': 'Футер',


  // User/profile
  'page.user-profile.user-name': 'Имя пользователя',
  'page.user-profile.admin': 'Администратор',
  'page.user-profile.first-name': 'Имя',
  'page.user-profile.placeholder-name': 'Иван',
  'page.user-profile.last-name': 'Фамилия',
  'page.user-profile.default-tool': 'Инструмент по умолчанию',
  'page.user-profile.tools': 'Инструменты',
  'page.user-profile.placeholder-input-search': 'Введите текст для поиска',
  'page.user-profile.groups': 'Gruppen',

  // Instance editor
  'component.instance-editor.image': 'Изображение',
  'component.instance-editor.text': 'Текст',
  'component.instance-editor.main': 'Основной',
  'component.instance-editor.additional': 'Дополнительный',

  // Switcher
  'component.switcher.enabled': 'Aktiviert ',

  // Select users groups
  'select-users-groups.users': 'Benutzer',

  // Tools navigator
  'component.tools-navigator.showTypes': 'Typen anzeigen',
  'component.tools-navigator.showBlocked': 'Nicht verfügbar anzeigen',

  // Tool settings common
  'component.tool-settings-common.permissions': 'Berechtigungen',
  'component.tool-settings-common.name': 'Name',
  'component.tool-settings-common.type': 'Typ',
  'component.tool-settings-common.label': 'Verknüpfung',
  'component.tool-settings-common.description': 'Beschreibung',
  'component.tool-settings-common.info': 'Info',
  'component.tool-settings-common.sureDelete': 'Sind Sie sicher, dass Sie das Werkzeug {name} löschen möchten?',

  // Table settings
  'component.table-settings.pagination': 'Seitennummerierung',
  'component.table-settings.rows': 'zeilen/seite',
  'component.table-settings.top': 'Oben',
  'component.table-settings.bottom': 'Unten',
  'component.table-settings.fillAndFit': 'Spalten auf die gesamte Breite dehnen',
  'component.table-settings.rowHeight': 'Zeilenhöhe',
  'component.table-settings.fontSize': 'Schriftgröße',
  'component.table-settings.fix': 'Fixieren',
  'component.table-settings.left': 'Links',
  'component.table-settings.right': 'Rechts',
  'component.table-settings.export': 'Exportieren',
  'component.table-settings.import': 'Importieren',

  // Range editor
  'component.range-editor.range': 'Bereich',
  'component.range-editor.visibility': 'Sichtbarkeit',
  'component.range-editor.useGlobalProperty': 'Globale Wert verwenden',
  'component.range-editor.presets': 'Voreinstellungen',
  'component.range-editor.defaultValue': 'Standardwert',
  'component.range-editor.absolute': 'Absolut',
  'component.range-editor.relative': 'Relativ',

  'component.range-preset.Last_5_minutes': 'letz. 5 Min.',
  'component.range-preset.Last_15_minutes': 'letz. 15 Min.',
  'component.range-preset.Last_30_minutes': 'letzte halbe St.',
  'component.range-preset.Last_1_hour': 'letz. Std.',
  'component.range-preset.Last_3_hours': 'letz. 3 Std.',
  'component.range-preset.Last_6_hours': 'letz. 6 Std.',
  'component.range-preset.Last_12_hours': 'letzte halbe T.',
  'component.range-preset.Last_24_hours': 'letz. Tag',
  'component.range-preset.Last_2_days': 'letz. 2 T.',
  'component.range-preset.Last_7_days': 'letz. Woche',
  'component.range-preset.Last_30_days': 'letz. Monat',
  'component.range-preset.Last_90_days': 'letz. Quartal',
  'component.range-preset.Last_6_months': 'letz. halbes J.',
  'component.range-preset.Last_1_year': 'letz. Jahr',
  'component.range-preset.Last_2_years': 'letz. 2 J.',
  'component.range-preset.Last_5_years': 'letz. 5 J.',
  'component.range-preset.Current_day': 'akt. Tag',
  'component.range-preset.Current_week': 'akt. Woche',
  'component.range-preset.Current_month': 'akt. Monat',
  'component.range-preset.Current_quarter': 'akt. Quartal',
  'component.range-preset.Current_year': 'akt. Jahr',
  'component.range-preset.Yesterday': 'gestern',
  'component.range-preset.Last_week': 'letz. Woche',
  'component.range-preset.Last_month': 'letz. Monat',
  'component.range-preset.Last_quarter': 'letz. Quartal',
  'component.range-preset.Last_year': 'letz. Jahr',

  // Updater editor
  'component.updater-editor.range': 'Aktualisierung',
  'component.updater-editor.visibility': 'Sichtbarkeit',
  'component.updater-editor.useGlobalValue': 'Globale Wert verwenden',
  'component.updater-editor.defaultValue': 'Standardwert',

  'component.updater-preset.Off': 'Aus',
  'component.updater-preset.5s': '5 Sek.',
  'component.updater-preset.10s': '10 Sek.',
  'component.updater-preset.30s': '30 Sek.',
  'component.updater-preset.1m': '1 Min.',
  'component.updater-preset.5m': '5 Min.',
  'component.updater-preset.15m': '15 Min.',
  'component.updater-preset.30m': '30 Min.',
  'component.updater-preset.1h': '1 Std.',
  'component.updater-preset.2h': '2 Std.',
  'component.updater-preset.1d': '1 Tag',

  // List extended
  'component.list-extended.selectAll': 'Alles auswählen',
  'component.list-extended.all': 'Alles',

  // Data input int
  'component.data-input-int.placeholder': 'Geben Sie eine ganze Zahl ein',

  // Data input settings
  'component.data-input-settings.contentType': 'Inhaltstyp',
  'component.data-input-settings.systemInformation': 'Systeminformation',
  'component.data-input-settings.dateInputFormat': 'Datumsformat',
  'component.data-input-settings.timeInputFormat': 'Zeitformat',
  'component.data-input-settings.dateTimeInputFormat': 'Datum und Zeitformat',
  'component.data-input-settings.editor': 'Editor',
  'component.data-input-settings.files': 'Dateien',
  'component.data-input-settings.coordinates': 'Koordinaten',
  'component.data-input-settings.week': 'Woche',
  'component.data-input-settings.month': 'Monat',
  'component.data-input-settings.quarter': 'Quartal',
  'component.data-input-settings.year': 'Jahr',
  'component.data-input-jpg-base64.upload': 'Hochladen',

  // Variable custom editor
  'component.variable.custom.editor': 'Wertesatz',
  'component.variable-custom-editor.preview-default-value': 'Vorschau/Standardwert',

  // Variable value editor
  'component.variable-value-editor.dataType': 'Datentyp',
  'component.variable-value-editor.boolean': 'Logisch',
  'component.variable-value-editor.int': 'Ganzzahlig',
  'component.variable-value-editor.float4': 'Fließkommazahl',
  'component.variable-value-editor.float': 'Fließkommazahl',
  'component.variable-value-editor.bpchar': 'Zeichenkette',
  'component.variable-value-editor.date': 'Datum',
  'component.variable-value-editor.time': 'Zeit',
  'component.variable-value-editor.timestamp': 'Zeitstempel',
  'component.variable-value-editor.bool': 'Logisches Array',
  'component.variable-value-editor.int4': 'Ganzzahliges Array',
  'component.variable-value-editor.float8': 'Fließkommazahlen Array',
  'component.variable-value-editor.varchar': 'Zeichenketten-Array',
  'component.variable-value-editor.uuid': 'Bezeichner',

  // Variable query editor
  'component.list-extended.templates': 'Vorlagen',
  'component.list-extended.basic': 'Grundlegend',
  'component.list-extended.textValueAliases': 'Text/Value-Alias',
  'component.list-extended.variables': 'Variablen',
  'component.list-extended.query': 'Abfrage',
  'component.list-extended.previewDefaultValue': 'Vorschau/Standardwert',

  // Row control
  'component.row-control.edit': 'Ändern',
  'component.row-control.move': 'Verschieben',
  'component.row-control.delete': 'Löschen',

  // Row mover
  'component.row-mover.move': 'Verschieben',
  'component.row-mover.rowNumber': 'Zeilennummer',

  // Variables editor
  'component.variables-editor.name': 'Name',
  'component.variables-editor.label': 'Etikett',
  'component.variables-editor.type': 'Typ',
  'component.variables-editor.attributes': 'Attribute',
  'component.variables-editor.actions': 'Aktionen',
  'component.variables-editor.confirmDelete': 'Sind Sie sicher, dass Sie die {name} Variable löschen möchten?',
  'component.variables-editor.var-value': 'Variable',
  'component.variables-editor.var-custom': 'Statische Liste',
  'component.variables-editor.var-query': 'Dynamische Liste',
  'component.variables-editor.dataTransfer': 'Datentransfer',
  'component.variables-editor.Auto': 'Auto',
  'component.variables-editor.Manual': 'Manuell',

  // Variable editor
  'component.variable-editor.add': 'Hinzufügen',
  'component.variable-editor.edit': 'Bearbeiten',
  'component.variable-editor.readOnly': 'Nur lesen',
  'component.variable-editor.visible': 'Sichtbar',
  'component.variable-editor.name': 'Name',
  'component.variable-editor.enterVariableName': 'Geben Sie den Variablennamen ein',
  'component.variable-editor.label': 'Etikett',
  'component.variable-editor.description': 'Beschreibung',
  'component.variable-editor.type': 'Typ',
  'component.variable-editor.width': 'Breite',
  'component.variable-editor.invalidName': 'Ungültiger Name',

  // Table fields
  'component.table-fields.name': 'Name',
  'component.table-fields.label': 'Etikett',
  'component.table-fields.type': 'Typ',
  'component.table-fields.attributes': 'Attribute',
  'component.table-fields.actions': 'Aktionen',
  'component.table-fields.totalItems': 'Gesamtanzahl {i}',

  // Table form editor
  'component.table-form-editor.preview': 'Vorschau',
  'component.table-form-editor.min': 'Min.',
  'component.table-form-editor.max': 'Max.',
  'component.table-form-editor.minHeight': 'Min. Höhe',
  'component.table-form-editor.maxHeight': 'Max. Höhe',
  'component.table-form-editor.width': 'Breite',
  'component.table-form-editor.minWidth': 'Min. Breite',
  'component.table-form-editor.maxWidth': 'Max. Breite',
  'component.table-form-editor.columns': 'Spalten',
  'component.table-form-editor.minimumReached': 'Minimum erreicht. Geben Sie zuerst Speicherplatz frei und ändern Sie dann die Anzahl der Spalten.',

  // Field sorting editor
  'modal.field-sorting-editor.edit': 'Bearbeiten',
  'modal.field-sorting-editor.field': 'Feld',
  'modal.field-sorting-editor.sortingRule': 'Sortierregel',
  'modal.field-sorting-editor.noSorting': 'Keine Sortierung',
  'modal.field-sorting-editor.asc': 'Aufsteigend',
  'modal.field-sorting-editor.asc_nulls_first': 'Aufsteigend, Null zuerst',
  'modal.field-sorting-editor.asc_nulls_last': 'Aufsteigend, Null zuletzt',
  'modal.field-sorting-editor.desc': 'Absteigend',
  'modal.field-sorting-editor.desc_nulls_first': 'Absteigend, Null zuerst',
  'modal.field-sorting-editor.desc_nulls_last': 'Absteigend, Null zuletzt',

  // Conditional formatting
  'component.conditional-formatting.confirmDelete': 'Sind Sie sicher, dass Sie die Regel {name} löschen möchten?',
  'component.conditional-formatting.name': 'Name',
  'component.conditional-formatting.description': 'Beschreibung',
  'component.conditional-formatting.attributes': 'Attribute',
  'component.conditional-formatting.actions': 'Aktionen',
  'component.conditional-formatting.totalItems': 'Gesamtanzahl {i}',

  // Conditional Formatting Editor
  'component.conditional-format-editor.add': 'Hinzufügen',
  'component.conditional-format-editor.edit': 'Bearbeiten',
  'component.conditional-format-editor.name': 'Name',
  'component.conditional-format-editor.enterRuleName': 'Geben Sie den Regelnamen ein',
  'component.conditional-format-editor.description': 'Beschreibung',
  'component.conditional-format-editor.fields': 'Felder',
  'component.conditional-format-editor.allFields': 'Alle Felder (ganze Zeile)',
  'component.conditional-format-editor.formatter': 'Formatter',
  'component.conditional-format-editor.fontColor': 'Schriftfarbe',
  'component.conditional-format-editor.backgroundColor': 'Hintergrundfarbe',
  'component.conditional-format-editor.font': 'Schriftart',
  'component.conditional-format-editor.bold': 'Fett',
  'component.conditional-format-editor.italic': 'Kursiv',
  'component.conditional-format-editor.underline': 'Unterstreichen',
  'component.conditional-format-editor.conditions': 'Bedingungen',

  // Table source
  'component.table-source.table': 'Tabelle',
  'component.table-source.selectTable': 'Tabelle auswählen',
  'component.table-source.description': 'Beschreibung',
  'component.table-source.options': 'Option',
  'component.table-source.main': 'Haupt',
  'component.table-source.aggregated': 'Aggregiert',
  'component.table-source.withKeys': 'Mit Schlüsseln',

  // Tools editor
  'component.tools-editor.source': 'Quelle',
  'component.tools-editor.permissions': 'Berechtigungen',
  'component.tools-editor.fields': 'Felder',
  'component.tools-editor.groups': 'Gruppen',
  'component.tools-editor.tableSettings': 'Tabelleneinstellungen',
  'component.tools-editor.preview': 'Vorschau',
  'component.tools-editor.formBuilder': 'Eingabe-/Ausgabe-Formular-Generator',
  'component.tools-editor.timeFunctions': 'Zeitfunktionen',
  'component.tools-editor.variables': 'Variablen',
  'component.tools-editor.conditions': 'Bedingungen',
  'component.tools-editor.sorting': 'Sortierung',
  'component.tools-editor.conditionalFormatting': 'Bedingte Formatierung',
  'component.tools-editor.constructor': 'Konstruktor',
  'component.tools-editor.matrix': 'Matrix',
  'component.tools-editor.additionalFormats': 'Zusätzliche Formate',
  'component.tools-editor.localizationSupport': 'Lokalisierungsunterstützung',
  'component.tools-editor.args': 'Argumente',

  // Table permissions
  'component.table-permissions.add': 'Hinzufügen',
  'component.table-permissions.update': 'Aktualisieren',
  'component.table-permissions.delete': 'Löschen',

  // Dashboard editor
  'component.dashboard-editor.add': 'Hinzufügen',
  'component.dashboard-editor.edit': 'Bearbeiten',
  'component.dashboard-editor.name': 'Name',
  'component.dashboard-editor.selectPanelName': 'Wählen Sie den Panelnamen aus',
  'component.dashboard-editor.localizationSupport': 'Lokalisierungsunterstützung',
  'component.dashboard-editor.view': 'Ansicht',
  'component.dashboard-editor.full': 'Voll',
  'component.dashboard-editor.kiosk': 'Kiosk',
  'component.dashboard-editor.kioskTV': 'Kiosk TV',
  'component.dashboard-editor.theme': 'Thema',
  'component.dashboard-editor.current': 'Aktuell',
  'component.dashboard-editor.light': 'Hell',
  'component.dashboard-editor.dark': 'Dunkel',
  'component.dashboard-editor.openToolOnClick': 'Werkzeug bei Klick öffnen',
  'component.dashboard-editor.selectTool': 'Werkzeug auswählen',

  // Dashboard designer
  'component.dashboard-designer.autoHeight': 'Automatische Höhe',
  'component.dashboard-designer.changePanelsInViewMode': 'Panels im Anzeigemodus ändern',
  'component.dashboard-designer.rowHeight': 'Zeilenhöhe',
  'component.dashboard-designer.columns': 'Spalten',

  // Dashboard editor
  'component.dashboard-panel.edit': 'Bearbeiten',
  'component.dashboard-panel.delete': 'Löschen',
  'component.dashboard-panel.confirmationMessage': 'Sind Sie sicher, dass Sie das {i}-Panel löschen möchten?',

  // Settings users
  'pages.settings-users.staticInformation': 'Statische Informationen',
  'pages.settings-users.created': 'Erstellt',
  'pages.settings-users.userName': 'Benutzername',
  'pages.settings-users.editableInformation': 'Bearbeitbare Informationen',
  'pages.settings-users.name': 'Name',
  'pages.settings-users.surname': 'Nachname',
  'pages.settings-users.changePassword': 'Passwort ändern',
  'pages.settings-users.newPassword': 'Neues Passwort',
  'pages.settings-users.enterNewPasswordAgain': 'Geben Sie das neue Passwort erneut ein',
  'pages.settings-users.groups': 'Gruppen',
  'pages.settings-users.tools': 'Werkzeuge',
  'pages.settings-users.confirmDelete': 'Sind Sie sicher, dass Sie den Benutzer {i} löschen möchten?',

  // Settings groups
  'pages.settings-groups.confirmDelete': 'Sind Sie sicher, dass Sie die Gruppe {i} löschen möchten?',
  'pages.settings-groups.users': 'Benutzer',
  'pages.settings-groups.subGroup': 'Untergruppe',

  // Settings services grafana
  'pages.settings-grafana-services.version': 'Version',
  'pages.settings-grafana-services.executionTime': 'Ausführungszeit',
  'pages.settings-grafana-services.description': 'Dies ist eine Beschreibung',
  'pages.settings-grafana-services.error': 'Fehler',
  'pages.settings-grafana-services.waiting': 'Warten',

  // Field editor simple
  'component.field-editor-simple.edit': 'Bearbeiten',
  'component.field-editor-simple.name': 'Name',
  'component.field-editor-simple.type': 'Typ',
  'component.field-editor-simple.description': 'Beschreibung',
  'component.field-editor-simple.label': 'Etikett',
  'component.field-editor-simple.parameters': 'Parameter',
  'component.field-editor-simple.keyField': 'Schlüsselfeld',
  'component.field-editor-simple.readOnly': 'Nur Lesen',
  'component.field-editor-simple.lazyLoading': 'Lazy loading',
  'component.field-editor-simple.quickView': 'Schnellansicht',
  'component.field-editor-simple.quickEdit': 'Schnellbearbeitung',
  'component.field-editor-simple.sorting': 'Sortierung',
  'component.field-editor-simple.filter': 'Filter',
  'component.field-editor-simple.foreignKey': 'Fremdschlüssel',
  'component.field-editor-simple.selectForeignKey': 'Wählen Sie den Fremdschlüssel aus',
  'component.field-editor-simple.formBuilder': 'Schnellansicht / Bearbeitungsformular-Generator',
  'component.field-editor-simple.inputOutputSettings': 'Ein-/Ausgabe-Einstellungen',
  'component.field-editor-simple.outputFormat': 'Ausgabeformat',

  // Field editor object
  'component.field-editor-object.edit': 'Bearbeiten',
  'component.field-editor-object.name': 'Name',
  'component.field-editor-object.type': 'Typ',
  'component.field-editor-object.description': 'Beschreibung',
  'component.field-editor-object.label': 'Etikett',
  'component.field-editor-object.parameters': 'Parameter',
  'component.field-editor-object.readOnly': 'Nur Lesen',
  'component.field-editor-object.filter': 'Filter',
  'component.field-editor-object.complexAddition': 'Komplexe Hinzufügung',
  'component.field-editor-object.groupFields': 'Felder gruppieren',
  'component.field-editor-object.complexAdditionFormBuilder': 'Komplexer Hinzufügungsformular-Generator',
  'component.field-editor-object.conditions': 'Bedingungen',
  'component.field-editor-object.sorting': 'Sortierung',

  // Field editor list
  'component.field-editor-list.edit': 'Bearbeiten',
  'component.field-editor-list.name': 'Name',
  'component.field-editor-list.type': 'Typ',
  'component.field-editor-list.description': 'Beschreibung',
  'component.field-editor-list.label': 'Etikett',
  'component.field-editor-list.parameters': 'Parameter',
  'component.field-editor-list.readOnly': 'Nur Lesen',
  'component.field-editor-list.filter': 'Filter',
  'component.field-editor-list.complexAddition': 'Komplexe Hinzufügung',
  'component.field-editor-list.viewerFormBuilder': 'Zeigenformular-Generator',
  'component.field-editor-list.complexAdditionFormBuilder': 'Komplexer Hinzufügungsformular-Generator',
  'component.field-editor-list.conditions': 'Bedingungen',
  'component.field-editor-list.sorting': 'Sortierung',

  // User creator
  'component.user-creator.add': 'Hinzufügen',
  'component.user-creator.user-name': 'Benutzername',
  'component.user-creator.enter-name': 'Geben Sie den Namen ein',
  'component.user-creator.invalidName': 'Ungültiger Name',

  // Group creator
  'component.group-creator.add': 'Hinzufügen',
  'component.group-creator.name': 'Name',
  'component.group-creator.enterName': 'Geben Sie den Namen ein',
  'component.group-creator.invalidName': 'Ungültiger Name',

  // Table editor
  'component.table-editor.add': 'Erstellen',
  'component.table-editor.edit': 'Bearbeiten',
  'component.table-editor.delete': 'Löschen',

  // table/viewer
  'component.table-viewer.total': 'Gesamtanzahl',
  'component.table-viewer.sort': 'Sortieren',
  'component.table-viewer.noSort': 'Keine Sortierung',
  'component.table-viewer.ascending': 'Aufsteigend',
  'component.table-viewer.ascendingEmptyBeginning': 'Aufsteigend (zu Beginn leer)',
  'component.table-viewer.ascendingEmptyEnd': 'Aufsteigend (am Ende leer)',
  'component.table-viewer.descending': 'Absteigend',
  'component.table-viewer.descendingEmptyBeginning': 'Absteigend (zu Beginn leer)',
  'component.table-viewer.descendingEmptyEnd': 'Absteigend (am Ende leer)',
  'component.table-viewer.filter': 'Filter',
  'component.table-viewer.tune': 'Einrichten',
  'component.table-viewer.clearFilter': 'Filter löschen',
  'component.table-viewer.clearAll': 'Alles löschen',

  // table-output-settings
  'component.table-output-settings.outputFormat': 'Ausgabeformat',

  // onboarding-editor
  'component.onboarding-editor.name': 'Name',
  'component.onboarding-editor.label': 'Verknüpfung',
  'component.onboarding-editor.description': 'Beschreibung',
  'component.onboarding-editor.attributes': 'Attribute',
  'component.onboarding-editor.actions': 'Aktionen',
  'component.onboarding-editor.edit': 'Bearbeiten',
  'component.onboarding-editor.delete': 'Löschen',
  'component.onboarding-editor.enabled': 'Aktiviert',
  'component.onboarding-editor.disabled': 'Deaktiviert',
  'component.onboarding-editor.tour': 'Tour',
  'component.onboarding-editor.add': 'Hinzufügen',
  'component.onboarding-editor.invalidName': 'Ungültiger Name',
  'component.onboarding-editor.totalItems': 'Gesamtanzahl {i}',

  // table/conditions
  'component.table-conditions.noVar': 'Keine Variable',
  'component.table-conditions.wrongVarType': 'Der Variablentyp stimmt nicht mit dem Feldtyp überein',
  'component.table-conditions.wrongConstType': 'Konstantentyp stimmt nicht mit Feldtyp überein',

  // onboarding-step-editor
  'component.onboarding-step-editor.title': 'Titel',
  'component.onboarding-step-editor.comment': 'Kommentar',
  'component.onboarding-step-editor.add': 'Hinzufügen',
  'component.onboarding-step-editor.edit': 'Bearbeiten',

  // base-modal-window
  'component.base-modal-window.cancel': 'Stornieren',

  // table-args
  'component.table-args.totalItems': 'Gesamtanzahl {i}',

  ...fileManager,
  ...logger,
  ...loggerViewerLocaleRu,
};

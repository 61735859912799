import { refreshReq } from "./api/keycloak/user/raw-requests";
import { getTokenPayload } from "./lib/common/jwt";
import { tokenStorage } from "./lib/storage/token";

export async function getInitialState() {

  const { getTokens, setTokens } = tokenStorage();
  const { refreshToken } = getTokens();

  let userInfo = null;
  if (!refreshToken) return { userInfo };

  try {
    const { access_token, refresh_token } = await refreshReq({ refreshToken });
    setTokens({ accessToken: access_token, refreshToken: refresh_token });

    userInfo = getTokenPayload(access_token);
  } catch {
    userInfo = null;
  }

  return { userInfo };
}

import component from './de-DE/component';
import globalHeader from './de-DE/globalHeader';
import menu from './de-DE/menu';
import pages from './de-DE/pages';
import pwa from './de-DE/pwa';
import settingDrawer from './en-US/settingDrawer';
import settings from './en-US/settings';
import messages from './de-DE/messages';

export default {
  'navBar.lang': 'Русский',
  'layout.user.link.help': 'Помощь',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.copyright.produced': 'Сделано правильными пацанами',
  'app.preview.down.block': 'Download this page to your local project',
  'app.welcome.link.fetch-blocks': 'Get all block',
  'app.welcome.link.block-list': 'Quickly build standard, pages based on `block` development',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  ...messages,
};

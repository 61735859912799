import { reducers } from "./reducers";
import { OnboardingSettings } from '@/models/settings/types';

export const applicationSettingsDefaultState = {
  color: 'default',
  style: 'light',
  layout: 'side',
  navTheme: 'light',
  logoImageUrl: null,
  logoEnabled: false,
  textEnabled: false,
  text: {},
  langSelectionEnabled: true,
  defaultLocale: 'ru-RU',
  optionLanguages: [],
  footerEnabled: false,
  logoMesoneEnabled: false,

};

export default {
  namespace: 'settings',
  state: {
    loaded: {
      application: false,
    },
    application: applicationSettingsDefaultState,
    onboarding: [] as OnboardingSettings
  },
  reducers,
};

export const loggerViewerLocaleEn = {
  'component.loggerViewer.columns.key': '#',
  'component.loggerViewer.columns.timestamp': 'Timestamp',
  'component.loggerViewer.columns.username': 'User',
  'component.loggerViewer.columns.action': 'Action',

  'component.loggerViewer.label.users': 'Users',
  'component.loggerViewer.label.range': 'Range',

  'component.loggerViewer.message.rangeRequired': 'Range is required!',

  'component.loggerViewer.button.show': 'Show',

};

export const loggerViewerLocaleRu = {
  'component.loggerViewer.columns.key': '№',
  'component.loggerViewer.columns.timestamp': 'Метка времени',
  'component.loggerViewer.columns.username': 'Пользователь',
  'component.loggerViewer.columns.action': 'Действие',

  'component.loggerViewer.label.users': 'Пользователи',
  'component.loggerViewer.label.range': 'Интервал',

  'component.loggerViewer.message.rangeRequired': 'Интервал обязательное поле!',

  'component.loggerViewer.button.show': 'Показать',
};


const tokenStorage = () => {

	const accessKey = "access-token";
	const refreshKey = "refresh-token";

	const getTokens = () => {
		const accessToken = localStorage.getItem(accessKey);
		const refreshToken = localStorage.getItem(refreshKey);
		return { accessToken, refreshToken };
	};
	const setTokens = ({
		accessToken, refreshToken
	}: { accessToken?: string; refreshToken?: string }) => {
		accessToken && localStorage.setItem(accessKey, accessToken);
		refreshToken && localStorage.setItem(refreshKey, refreshToken);
	};
	const removeTokens = () => {
		localStorage.removeItem(accessKey);
		localStorage.removeItem(refreshKey);
	};

	return { getTokens, setTokens, removeTokens };
};

export {
	tokenStorage
};
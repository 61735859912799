/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */

const canRole = (roles, role) => roles.includes(role);

export default function access(initialState) {
  const roles = initialState?.userInfo?.resource_access?.mes?.roles;
  const rolesRealm = initialState?.userInfo?.resource_access?.["realm-management"]?.roles;
  return {
    canAdmin: canRole(roles || [], 'admin'),
    canRealmAdmin: canRole(rolesRealm || [], 'realm-admin'),
  };
}

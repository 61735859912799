export default {
  'message.test-connection-success': 'Тест подключения прошел успешно',
  'message.test-connection-unsuccess': 'Тест подключения прошел неудачно!',
  'message.copiedToClipboard': 'Скопировано в буфер обмена',
  'message.error': 'Ошибка',
  'message.error2': 'Ошибка {text}',
  'message.success': 'Успешно',
  'message.unsuccess': 'Неудачно',
  'message.synced': 'Синхронизировано',
  'message.notsynced': 'Синхронизация прошла не удачно',
  'message.read-file-error': 'Ошибка чтения файла',
  'message.password-mismatch': 'Пароли не совпадают',
  'message.something-went-wrong': 'Что-то пошло не так',
  'message.sso-unavailable': 'Брокер авторизации недоступен!',
  'message.api-unavailable': 'Сервис API недоступен!',
  'message.noFieldsSelected': 'Не выбраны поля для добавления',
  'message.available-formats': 'Допустимые форматы: jpg, png, svg',
  'message.allowed-file-size': 'Размер файла не более 500кб',
  'message.allowed-image-size': 'Максимальный размер изображения 64x64px',
  'message.settings-saved-successfully': 'Настройки успешно сохранены',
  'message.settings-saved-failure': 'Не удалось получить глобальные настройки',
  'message.type-value': 'Введите значение!',
  'message.select-variable': 'Выберите переменную!',
};
